.building-form {
    --font-size-title: var(--font-size-xxlarge);

    --padding-v: 0;
    --padding-h: var(--padding-m);

    --padding-inner-v: var(--padding-large);
    --padding-inner-h: var(--padding-large);

    --gap: var(--padding-m);
    --radius: #{rem(16)};
    
    --img-height: #{rem(680)};
    --img-width: #{rem(680)};

    --bg: var(--dark-blue);
    --color: var(--white);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);

        --padding-inner-v: var(--padding-xs);
        --padding-inner-h: var(--padding-xs);
    }
}

.building-form {
    @include font-sans-bold();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    margin-bottom: var(--padding-m);
    
    .wrapper {
        border-radius: var(--radius);
        padding: var(--padding-inner-v) var(--padding-inner-h);
        background-color: var(--bg);
        color: var(--color);
    }

    .map {
        border-radius: var(--radius);
        overflow: hidden;
        background-color: var(--blue);
        position: relative;

        .holder {
            --zoom: 1;

            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            top: 0;
            left: 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }

    .title {
        @include font-sans-heavy();
        font-size: var(--font-size-title);
        text-transform: uppercase;
        margin-bottom: var(--padding-l);
    }

    @media (max-width: $smartphone) {
        .map {
            width: 100%;
            padding-top: 100%;
            margin-bottom: var(--padding-l);
        }
    }

    @media (min-width: $smartphone) {
        .wrapper {
            display: flex;
            gap: var(--gap);
        }
 
        .map {
            width: var(--img-width);
            flex: 0 0 var(--img-width);
            min-height: var(--img-height);

            &.--full {
                width: 100%;
                flex: 0 0 100%;
            }
        }

        .title {
            margin-bottom: var(--padding-large);
        }
    }
}
