
.card-building {
    --font-size: var(--font-size-xxsmall);
    --radius: var(--padding-xxxs);
    --icon-holder-size: var(--padding-m);
    --icon-holder-radius: var(--padding-xxxxxs);
    --icon-font-size: var(--font-size-xxxsmall);
    --bg: var(--grey);
    
    .--list & {
        --font-size: var(--font-size-base);
        --bg: var(--lighterGrey);
    }

    @media (max-width: $smartphone) {
        --icon-holder-size: 18px;
    }
      
    @include isCursor() {
        .--list &:not(.--disabled):hover {
            --bg: var(--blue);
        }
    }
}

.card-building {
    --opacity-main: 0;
    --opacity-aux: 1;
    --scale-main: 1.2;
    --scale-aux: 1;

    &.card-date {
        --opacity-aux: .58;
        background-color: var(--black);
    }

    @include isCursor() {
        .--grid &:not(.--disabled):hover {
            --opacity-main: 1;
            --opacity-aux: 0;
            --scale-main: 1;
            --scale-aux: 1.2;
        }
    }

    .--grid & {
        --opacity-main: 0;
        --opacity-aux: 1;
        --scale-main: 1.2;
        --scale-aux: 1;

        border-radius: var(--radius);

        .title-list,
        .address {
            display: none;
        }
        
        .title,
        .title-mobile {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: flex-start;
            gap: var(--padding-xxxxxs);
        }

        .title {
            padding: var(--padding-xxs);
        }

        .title,
        > .info {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        > .info > div {
            display: flex;
            gap: var(--padding-xxxxxs);

            &.--row { 
                flex-direction: row;
    
                &.--top { align-items: flex-start; }
                &.--bottom { align-items: flex-end; }
                &.--left { justify-content: flex-start; }
                &.--right { justify-content: flex-end; }
            }
            
            &.--column { 
                flex-direction: column;
    
                &.--left { align-items: flex-start; }
                &.--right { align-items: flex-end; }
                &.--top { justify-content: flex-start; }
                &.--bottom { justify-content: flex-end; }
            }
        }

        @media (max-width: $smartphone) {
            > .info {
                padding: var(--padding-xxs);
                gap: var(--padding-xxxxxs);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .--row.--top.--right {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: var(--padding-xxs);
                }
            }
        }

        @media (min-width: $smartphone) {
            > .info > div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: var(--padding-xxs);
            }
        }

        > .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 120%;

            .main-image {
                opacity: var(--opacity-main);
                transform: scale3d(var(--scale-main), var(--scale-main), var(--scale-main));
            }

            .aux-image {
                opacity: var(--opacity-aux);
                transform: scale3d(var(--scale-aux), var(--scale-aux), var(--scale-aux));
            }
        }
    }
    
    .--list & {
        --opacity-main: 1;
        --opacity-aux: 1;
        --scale-main: 1;
        --scale-aux: 1;
        
        padding: var(--padding-xxs);
        margin: var(--padding-xxs) 0;
        display: block;

        .title,
        .title-mobile,
        .icono-text > span:last-child {
            display: none !important;
        }

        // .icono.--white,
        // .icono-text.--white,
        // .icono-text { --bg: var(--lighterGrey); }

        @media (min-width: $smartphone) {
            display: flex;
            align-items: center;
            gap: var(--padding-m);
            
            .title-list {
                flex: 0 0 rem(350);
            }
            
            .address {
                flex: 0 0 rem(230);
            }
            
            > .info,
            > .info > div {
                display: flex;
                gap: var(--padding-xxxxs);
            }
        }
        
        @media (max-width: $smartphone) {
            .title-list,
            .address {
                margin-bottom: var(--padding-xxxs);
            }
            
            > .info,
            > .info > div {
                display: flex;
                gap: var(--padding-xxs);
            }

            > .bg {
                display: none;
            }
        }


        > .bg {
            width: rem(62);
            height: rem(62);
            border-radius: rem(8);
            transform: none !important;
            position: relative;

            .aux-image {
                display: none;
            }
        }
    }

    @include basic-a();
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--bg);

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: opacity .2s ease-out, transform .2s ease-out;
    }
    
    .title,
    .address {
        opacity: var(--opacity-main);
        transform: scale3d(var(--scale-main), var(--scale-main), var(--scale-main));
    }
    
    .address {
        @include font-sans-bold(1);
        color: var(--white);
    
        > span {
            font-size: var(--icon-font-size);
            padding: .12em .4em 0;
            border-radius: 1em;
            background-color: var(--black);
            color: var(--white);
        }
    }

    .title-list,
    .address {
        @include font-sans-bold(1);
        font-size: var(--font-size);
        color: var(--black);
    }

    .title-list {
        font-size: var(--font-size);
    }
    
    .address {
        font-size: var(--font-size-xxsmall);
    }

    .title,
    .title-mobile {
        @include font-sans-bold(1);
        color: var(--white);
    
        > span {
            font-size: var(--font-size);
            border-radius: .3em;
            background-color: var(--black);
            padding: .2em .4em .1em;
        }
    }
    
    > .info {
        opacity: var(--opacity-aux);
        transform: scale3d(var(--scale-aux), var(--scale-aux), var(--scale-aux));
        transform-origin: center center;
    
        > div {
            user-select: none;
            pointer-events: none;
        }
    }
    
    .icono-text {
        --bg: var(--white);
    
        &.--green { --bg: var(--green); }
        &.--blue { --bg: var(--blue); }
        &.--red { --bg: var(--red); }
        &.--white { --bg: var(--white); }
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: var(--icon-font-size);
        @include font-sans-bold(1);
    
        > span:nth-child(2) {
            padding: .12em .4em 0;
            border-radius: 1em;
            background-color: var(--white);
            text-transform: uppercase;
            background-color: var(--bg);
            color: var(--black);
    
            &.--capitalize {
                text-transform: capitalize;
            }
        }
    }
    
    .icono {
        &.--green { --bg: var(--green); }
        &.--blue { --bg: var(--blue); }
        &.--red { --bg: var(--red); }
        &.--white { --bg: var(--white); }
    
        position: relative;
        display: flex;
        width: var(--icon-holder-size);
        height: var(--icon-holder-size);
        border-radius: var(--icon-holder-radius);
        background-color: var(--bg);
        padding: var(--padding-xxxxxs);
        
        svg {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}
