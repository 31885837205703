@use "sass:math";

:root {
    --y-header: 0;

    --padding-xxxxl: #{math.div(140px, 16px) * 1rem};
    --padding-xxxl: #{math.div(105px, 16px) * 1rem};
    --padding-xxl: #{math.div(85px, 16px) * 1rem};

    --padding-xl: #{math.div(55px, 16px) * 1rem};
    --padding-large: #{math.div(48px, 16px) * 1rem};
    --padding-l: #{math.div(30px, 16px) * 1rem};
    --padding-m: #{math.div(20px, 16px) * 1rem};
    --padding-s: #{math.div(15px, 16px) * 1rem};
    --padding-xs: #{math.div(10px, 16px) * 1rem};
    --padding-xxs: #{math.div(8px, 16px) * 1rem};
    --padding-xxxs: #{math.div(5px, 16px) * 1rem};
    --padding-xxxxs: #{math.div(4px, 16px) * 1rem};
    --padding-xxxxxs: #{math.div(3px, 16px) * 1rem};

    --header-height: #{math.div(334px, 16px) * 1rem};
    
    --scrollbar-height: #{math.div(40px, 16px) * 1rem};

    // @media (max-width: $smartphone) {
    // }
}
