.marker {
    @media (min-width: $smartphone) {
        --icon-holder-size: var(--padding-m);
        --icon-size: var(--padding-l);
        --icon-holder-radius: var(--padding-xxxxxs);
        --icon-font-size: var(--font-size-xxxsmall);
    }

    @media (max-width: $smartphone) {
        --icon-holder-size: var(--padding-m);
        --icon-size: var(--padding-l);
        --icon-holder-radius: var(--padding-xxxxxs);
        --icon-font-size: var(--font-size-xxxsmall);
    }
}

.marker {
    --z: 1;
    --scale: 1;
    --icon-size: calc(var(--padding-l) * var(--zoom));

    @include basic-a();
    z-index: var(--z);
    position: absolute;
    
    @include font-sans-bold(1);
    
    font-size: var(--icon-font-size);
    
    color: var(--white);
   
    transform: scale3d(var(--scale), var(--scale), var(--scale));
    transition: transform .4s ease-out;

    > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,2px);
        display: none;
        white-space: nowrap;
        width: auto;
        min-width: var(--icon-holder-size);
        border-radius: var(--icon-holder-radius);
        background-color: var(--black);
       
        padding: .2em .4em;
    }

    > svg {
        width: var(--icon-size);
        height: var(--icon-size);
    }

    &:hover {
        --z: 2;
        --scale: 2;
        transition: transform .1s ease-out;
        
        svg,
        > svg {
            opacity: 0;
        }

        > span {
            display: block;
        }
    }
}
