.building-billboard {
    --font-size: var(--font-size-medium);
    --font-size-small: var(--font-size-base);
    --font-size-title: var(--font-size-xxxlarge);

    --color: var(--black);

    --padding-h: var(--padding-xxxl);
    --padding-top: var(--padding-xxl);
    --padding-bottom: var(--padding-large);
    
    --gap: var(--padding-xs);
    --width-slide: 70vw;
    --height: auto;
    --max-height: auto;
    --radius: #{rem(16)};

    --panel-width: #{rem(420)};
    
    --logo-width: #{rem(199)};
    --logo-height: #{rem(63)};

    .eyes {
        --width-eyes: #{rem(45)};
        --height-eyes: #{rem(24)};
    }

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --padding-top: var(--padding-xxl);
        --padding-bottom: var(--padding-l);
        --width-slide: 85vw;
        --height: auto;
    }
}

.building-billboard {
    .wysiwyg {
        @include font-sans-heavy();
        font-size: var(--font-size);
        color: var(--color);
        max-width: rem(763);
        
        p:not(:last-child) {
            margin-bottom: var(--padding-m);
        }

        strong, b {
            font-weight: 800;
        }
    }
    
    .eyes {
        margin-bottom: var(--padding-s);
    }
    
    .info {
        @include font-sans-heavy();
        font-size: var(--font-size-small);
        color: var(--color);
        margin-top: var(--padding-large);
        
        > div:not(:last-child) {
            margin-bottom: var(--padding-xs);
        }
        
        .subtitle {
            margin-top: .8em;
        }
    }
    
    &__wrapper {
        padding: 0 var(--gap);
        
        .features {
            background-color: var(--white);
            border-radius: var(--radius);
            
            .bottom {
                padding: 0 var(--gap) var(--gap);
                margin-top: var(--padding-s);
                
                p {
                    @include font-sans-bold();
                    font-size: var(--font-size-small);
                    color: var(--color);
                    padding: var(--padding-m) var(--padding-m);
                    text-align: center;
                }
            }
            
            .categs {
                display: flex;
                padding: var(--gap) var(--gap) 0;
                gap: var(--padding-xxs);
                scroll-snap-type: x mandatory;	
                -webkit-overflow-scrolling: touch;
                overflow-x: scroll;

                // Hide scrollbars
                scrollbar-width: none; // Firefox
                -ms-overflow-style: none; // IE 10+
                &::-webkit-scrollbar {
                    display: none; // WebKit
                }
            }
            
            .top {
                display: flex;
                justify-content: flex-end;
                padding: var(--padding-xxs) var(--gap) 0;
                
                svg {
                    width: var(--logo-width);
                    height: var(--logo-height);
                }
            }
            
            .info {
                margin-top: var(--padding-l);
                padding: 0 var(--padding-s) var(--padding-s);
                
                li {
                    &:not(:last-child) {
                        margin-bottom: var(--gap);
                    }

                    svg {
                        position: relative;
                        top: rem(2);
                        height: rem(20);
                        width: rem(20);
                    }
                }
            }
        }

        @media (max-width: $smartphone) {
            .features {
                margin: var(--padding-xs) 0;

                .categs {
                    scroll-snap-type: x mandatory;	
                    -webkit-overflow-scrolling: touch;
                    overflow-x: scroll;
                    
                    // Hide scrollbars for mobile
                    scrollbar-width: none; // Firefox
                    -ms-overflow-style: none; // IE 10+
                    &::-webkit-scrollbar {
                        display: none; // WebKit
                    }
                }
            }
        }

        @media (min-width: $smartphone) {
            display: flex;
            gap: var(--gap);
        
            .title {
                flex: 0 0 65%;
            }

            .features {
                @include z-index(1);
                position: absolute;
                left: 65%;
                flex: 0 0 var(--panel-width);

                .categs {
                    justify-content: flex-end;
                }
            }
        }
    }

    &__info {
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    }

    &__slider {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }

        > .holder {
            order: 1;
            position: relative;
            height: var(--height);
            max-height: var(--max-height);
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            gap: var(--gap);
        }

        @media (max-width: $smartphone) {
            > .holder {
                scroll-snap-type: x mandatory;	
                -webkit-overflow-scrolling: touch;
                overflow-x: scroll;
                padding-right: var(--gap);
                padding-left: var(--gap);
                
                // Hide scrollbars for mobile
                scrollbar-width: none; // Firefox
                -ms-overflow-style: none; // IE 10+
                &::-webkit-scrollbar {
                    display: none; // WebKit
                }
            }
        }

        @media (min-width: $smartphone) {
            margin-top: calc(var(--font-size-title) * -1.5);
        }
    }

    &__item {
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        border-radius: var(--radius);
        height: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
        }

        @media (max-width: $smartphone) {
            &:first-child,
            &:last-child {
                display: none;
            }
        }

        @media (min-width: $smartphone) {
            &:first-child,
            &:last-child {
                width: 0;
                flex: 0 0 0;
            }
        }
    }
}

.building-billboard__wrapper .title {
    position: relative;
    @include z-index(1);
    @include font-sans-heavy(1);
    font-size: var(--font-size-title); 
    display: flex;
    flex-wrap: wrap;
    gap: var(--padding-xxxxs);
    
    span {
        display: block;
        background-color: black;
        padding: .1em .15em 0em;
        border-radius: .15em;
        background-color: var(--pink);
        color: var(--black);

        &:first-child {
            padding-left: .5em;
            border-top-left-radius: .55em;
            border-bottom-left-radius: .55em;
            background-color: var(--blue);
            text-transform: uppercase;
        }

        &:last-child {
            padding-right: .5em;
            border-top-right-radius: .55em;
            border-bottom-right-radius: .55em;
            background-color: var(--blue);
            text-transform: uppercase;
        }
    }
}
