.digits {
    display: flex;
    gap: var(--padding-xs);
    
    @media (max-width: $smartphone) {
        gap: var(--padding-xxxs);
    }
}

.digit {
    --padding-top: var(--padding-s);
    --padding-bottom: var(--padding-xxxs);
    --padding-h: var(--padding-xs);
    
    --radius: #{rem(16)};
    --bg: var(--white);
    --color: var(--black);
    --font-size: var(--font-size-xxxlarge);
    --width: #{rem(95)};
    --height: #{rem(95)};

    @media (max-width: $smartphone) {
        --padding-top: var(--padding-xs);
        --width: #{rem(68)};
        --height: #{rem(68)};    
        --radius: #{rem(12)};
    }
}

.digit {
    @include basic-a();
    color: var(--color);
    background-color: var(--bg);
    
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    min-width: var(--width);
    height: var(--height);
    border-radius: var(--radius);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include font-sans-heavy(.7);
    font-size: var(--font-size);
    text-transform: uppercase;
}