#Footer {
    --padding-h: var(--padding-l);
    --padding-v: var(--padding-l);
    --margin: var(--padding-xxxxl);
    --arrow: #{rem(72)};
    --height-icon: #{rem(62)};
    --collab-logos-aux-height: #{rem(50)};
    --collab-logos-aux-width: #{rem(100)};
    --collab-logos-size: #{rem(50)};
    --collab-padding: var(--padding-s) var(--padding-m);
    --collab-gap: var(--padding-xs);
    --collab-radius: #{rem(25)};
    --collab-margin: var(--padding-m);
    --collab-title: var(--font-size-xs);
    
    @media (max-width: $smartphone) {
        --arrow: #{rem(26)};
        --height-icon: #{rem(20)};
        --collab-margin: var(--padding-xxxs);
        --collab-logos-size: #{rem(52)};
        --margin: var(--padding-xl);
        --padding-h: var(--padding-xs);
        --padding-v: var(--padding-s);
    }
}

#Footer > .collab-logos {
    width: 100%;
    background-color: var(--white);
    border-radius: var(--collab-radius);
    padding: var(--collab-padding);
    margin: var(--collab-margin) 0;

    > .title {
        @include font-sans-heavy(1);
        font-size: var(--collab-title);
        color: var(--black);
        text-transform: uppercase;
        text-align: left;
        margin-bottom: .94em;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: var(--collab-gap);
    }

    li {
        position: relative;
        flex: 0 0 auto;
        display: block;
        width: auto;
        height: var(--collab-logos-size);
        max-width: 100%;

        &.--aux {
            flex: 0 0 var(--collab-logos-aux-width);
            width: var(--collab-logos-aux-width);
            height: var(--collab-logos-aux-height);

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }

        img {
            width: auto;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            object-position: center center;
        }
        
        @media (max-width: $smartphone) {
            img {
                object-fit: contain;
            }
        }
    }
}

#Footer {
    padding: 0 var(--padding-h) var(--padding-v);
    margin-top: var(--margin);
    
    a,
    button {
        @include basic-a();
        color: var(--white);
        padding: 0;
        background-color: transparent;
        text-align: left;
    }

    .top {
        .icon {
            width: 100%;
            height: auto;
        }
    }

    .contact,
    .rrss {
        a {
            height: var(--height-icon);
            
            svg {
                height: var(--height-icon);
                width: auto;
            }
        }
    }

    .legals {
        @include font-sans-bold();
        font-size: var(--font-size-xsmall);
    }

    .contact {
        .icon--arrow-left {
            height: var(--arrow);
            width: var(--arrow);
        }
    }

    @media (max-width: $smartphone) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .rrss {
            height: var(--height-icon);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: rem(2);
            margin-bottom: var(--padding-xxxxs);
        }
        
        .legals {
            margin-top: var(--padding-xxxxs);
            justify-content: flex-end;
        }

        .legals,
        .legals > div {
            display: flex;
            gap: var(--padding-xxs);
        }

        .contact {
            align-items: center;
            display: flex;
            justify-content: flex-end;

            .eyes {
                margin-right: var(--padding-xxs);
            }
        }

        .bottom {
            display: flex;
            flex-direction: column-reverse;
        }
    }
    
    @media (min-width: $smartphone) {
        .bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--padding-xs);
            margin-top: var(--padding-xs);
        }

        .legals {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--padding-s);
            
            > div {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                > *:not(:last-child) {
                    margin-bottom: var(--padding-s);
                }
            }
        }

        .rrss,
        .contact {
            height: var(--height-icon);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: rem(2);
        }

        .top {
            margin-bottom: var(--padding-v);
        }
    }
}
