.widget-landing {
    display: none !important;

    --padding: var(--padding-l);

    --width-content: auto;
    --height-content: 100%;

    --width-content-holder: auto;
    --height-content-holder: #{rem(722.56)};

    --width-arrow: 10.8%;
    
    --width-eyes: #{rem(150)};
    --height-eyes: #{rem(80)};
    --width-cta1: #{rem(360)};
    --height-cta1: #{rem(50)};
    --width-cta2: #{rem(500)};
    --height-cta2: #{rem(50)};

    @media (max-width: $smartphone) {
        --padding: var(--padding-xs);
        --width-content: 100%;
        --height-content: auto;

        --width-content-holder: 100%;
        --height-content-holder: auto;
    }
    
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    .content {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        padding: var(--padding);

        @media (max-width: $smartphone) {
            padding: var(--padding-xl) var(--padding) 0;
        }

        > div {
            position: relative;
            width: var(--width-content-holder);
            height: var(--height-content-holder);
            max-height: calc(100vh - var(--padding) * 2);

            > svg {
                position: relative;
                width: var(--width-content);
                height: var(--height-content);
            }
        }

        .arrow {
            position: absolute;
            bottom: 8.6%;
            right: 2.1%;
            width: var(--width-arrow);
            height: auto;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }

    .cta {
        position: absolute;
        left: var(--padding);
        bottom: var(--padding);

        .eyes {
            width: var(--width-eyes);
            height: var(--height-eyes);
            margin-bottom: .2rem;

            svg:first-of-type {
                position: absolute;
                top: 0;
                left: 0;
                transform-origin: center center;
            }

            svg:last-of-type {
                position: relative;
                transition: transform .1s;
            }

            svg {
                width: var(--width-eyes);
                height: var(--height-eyes);
            }
        }

        a {
            display: flex;
            flex-direction: column;

            svg:first-of-type {
                width: var(--width-cta1);
                height: var(--height-cta1);
                margin-bottom: .2rem;
            }

            svg:last-of-type {
                width: var(--width-cta2);
                height: var(--height-cta2);
            }
        }
    }
}
