:root {
    --white: #ffffff;
    --black: #000000;
    --darkGrey: #20232B;
    --grey: #57627A;
    --lightGrey: #A9AFBB;
    --lighterGrey: #D9D9D9;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;

    --blue: #4374FF;
    --dark-blue: #2a2f38;
    --green: #19FF94;
    --pink: #FF2C9B;

    .--lang-eu {
        --blue: #19FF94;
        --green: #4374FF;
    }
}
