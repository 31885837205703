.edition-billboard {
    --font-size: var(--font-size-xxxlarge);

    --radius: #{rem(25)};
}

.edition-billboard {
    h1 {
        @include font-sans-heavy();
        font-size: var(--font-size);
    }

    .wrapper {
        @include z-index(1);
        align-items: center;
        display: flex;
        padding: 0;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
    
    .wrapper,
    .media-holder,
    .overlay {
        height: 60vh;
    }

    .overlay {
        @include z-index(0);
        background-color: var(--black);
        left: 0;
        opacity: .15;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .media-holder {
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img,
        video {
            object-fit: cover;
        }
    }

    .editions {
        display: flex;
        flex-direction: row-reverse;
        gap: rem(2);
        margin-top: var(--padding-l);
        padding: rem(2);
        background-color: var(--pink);
        border-radius: var(--radius);
    }
    
    @media (max-width: $smartphone) {
        .wrapper,
        .media-holder,
        .overlay {
            height: 80vh;
        }
            
        .editions {
            margin-top: var(--padding-s);
        }
    }
}
