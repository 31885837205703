@use "sass:math";

#Header {
    --logo-width: #{rem(360)};
    --logo-height: #{rem(114)};
    
    --burger-width: 27px;
    --burger-height: 60px;
    
    .eyes {
        --width-eyes: #{rem(45)};
        --height-eyes: #{rem(24)};
    }
    
    @media (max-width: $smartphone) {
        --logo-width: 100%;
        --logo-height: auto;
    }
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    right: 0;
    top: 0;

    .wrapper,
    .toggle-menu {
        @include z-index(1);
    }
    
    .overlay {
        @include z-index(0);
        width: 100%;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .2);
        padding: 0;
    }
    
    .logo {
        @include basic-a();
        width: var(--logo-width);
        height: var(--logo-height);
        display: inline-block;
        transform-origin: top right;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    nav {
        max-width: var( --logo-width);

        .bottom, ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        ul li,
        .bottom {
            margin-top: var(--padding-xxxs);
        }

        .langs {
            display: flex;
            padding: 0;

            a.--is-active {
                pointer-events: none;
            }
        }

        .eyes {
            margin-right: var(--padding-xxxs);
        }
    }

    .toggle-menu {
        background-color: transparent;
        padding: 0;
        width: var(--burger-width);
        height: var(--burger-height);
        
        svg {
            flex: 0 0 var(--burger-width);
            width: var(--burger-width);
            height: var(--burger-height);
        }
    }

    @media (max-width: $smartphone) {
        display: flex;
        align-items: flex-start;
        padding: 0 var(--padding-xs);
        gap: var(--padding-xxxs);
        width: 100%;

        .wrapper {
            flex-grow: 1;
            text-align: right;
        }

        .logo {
            &.--closed {
                width: 50vw;
                height: auto;
                transition: .2s var(--ease-out-cubic);
            }

            transition: .4s var(--ease-out-cubic);
        }
    }
}
