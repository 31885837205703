.block-project-map-list {    
    @media (min-width: $smartphone) {
        --width: 100%;
        --height: 80vw;
        --max-height: 90vh;
        --padding: var(--padding-xs);
        --radius: var(--padding-xxxs);

        // --icon-holder-size: var(--padding-m);
        // --icon-size: var(--padding-l);
        // --icon-holder-radius: var(--padding-xxxxxs);
        // --icon-font-size: var(--font-size-xxxsmall);
    }

    @media (max-width: $smartphone) {
        --width: 100%;
        --height: 80vw;
        --max-height: 90vh;
        --padding: var(--padding-xs);
        --radius: var(--padding-xxxs);

        // --icon-holder-size: var(--padding-m);
        // --icon-size: var(--padding-l);
        // --icon-holder-radius: var(--padding-xxxxxs);
        // --icon-font-size: var(--font-size-xxxsmall);
    }
}

.block-project-map-list {
    position: relative;
    width: var(--width);
    height: var(--height);
    max-height: var(--max-height);
    padding: var(--padding);
}

.block-project-map-list .holder {
    --zoom: 1;

    position: relative;
    width: 100%;
    height: 100%;
    border-radius: var(--radius);
    overflow: hidden;
    background-color: var(--blue);
}

.block-project-map-list + .block-project-list {
    ul {
        padding-top: 0;
    }
}

.block-project-list + .block-project-map-list {
    padding-top: 0;
}
