.not-found {
    --padding-h: var(--padding-xs);
}

.not-found {
    @include font-sans-bold();

    padding: 35vh var(--padding-h) 5vh;

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        text-align: center;
    }

    .text {
        margin-top: var(--padding-xl);
        text-align: center;

        a {
            display: inline-block;
            text-align: center;
        }
    }

    .icon {
        width: 80%;
        margin: auto;

        svg {
            width: 100%;
            height: auto;
        }
    }

    @media (max-width: $smartphone) {
        padding: 35vh var(--padding-h) 5vh;

        .icon {
            width: 100%;
        }

        .text {
            margin-top: var(--padding-l);
        }
    }
}
