.eyes {
    --width-eyes: #{rem(127)};
    --height-eyes: #{rem(68)};

    @media (max-width: $smartphone) {
        --width-eyes: #{rem(37)};
        --height-eyes: #{rem(20)};
    }
}

.eyes {
    width: var(--width-eyes);
    height: var(--height-eyes);
    margin-bottom: .2rem;
    position: relative;

    svg:first-of-type {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: center center;
    }

    svg:last-of-type {
        position: relative;
        transition: transform .1s;
    }

    svg {
        width: var(--width-eyes);
        height: var(--height-eyes);
    }
}