.color-tag {
    --radius: 1.01em;

    @include font-sans-bold(1.2);
    background-color: var(--bg);
    color: var(--color);
    border-radius: var(--radius);
    padding: 0 .15em;
    display: inline-block;

    &.--blue {
        --color: var(--black);
        --bg: var(--blue);
    }
    
    &.--white {
        --color: var(--black);
        --bg: var(--white);
    }
    
    &.--pink {
        --color: var(--black);
        --bg: var(--pink);
    }
}
