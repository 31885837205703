.filter {
    --color: var(--black);
    --font-size: var(--font-size-base);
    --padding: .42em 0;
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --padding: .21em 0;
    }
}

.filter {
    @include basic-a();
    color: var(--color);
    background-color: transparent;
    text-align: left;
    display: flex;
    align-items: center;
    position: relative;
    pointer-events: all;

    @include font-sans-heavy(.7);
    font-size: var(--font-size);
    width: 100%;
    padding: .42em 0;

    &::before {
        border: 1px solid var(--color);
        content: '';
        display: block;
        height: 1em;
        margin-right: .42em;
        min-width: 1em;
        position: relative;
        width: 1em;
        border-radius: 1em;
    }

    &::after {
        background-color: var(--color);
        content: '';
        display: block;
        height: .5em;
        min-width: .5em;
        position: relative;
        width: .5em;
        border-radius: .5em;

        position: absolute;
        left: .25em;

        display: none;
    }

    > span {
        margin-top: .16em;
    }

    &.--active {
        pointer-events: none;
    }

    &.--active,
    &.--selected {
        &::after {
            display: block;
        }
    }

    @include isCursor {
        &:hover::after {
            display: block;
        }
    }
}