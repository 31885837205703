.edition-gallery {
    --gap: var(--padding-xs);
    --height: #{rem(725)};
    --radius: #{rem(8)};
    
    @media (min-width: $smartphone) {
        --radius: #{rem(16)};
    }
}

.edition-gallery {
    padding: 0 var(--gap);

    .row {
        &:not(:last-child) {
            margin-bottom: var(--gap);
        }

        figure {
            border-radius: var(--radius);
            overflow: hidden;
            
            img {
                height: auto;
                width: 100%;
            }
        }
    }

    @media (max-width: $smartphone) {
        .row.--double {
            figure:not(:last-child) {
                margin-bottom: var(--gap);
            }
        }
    }

    @media (min-width: $smartphone) {
        .row {  
            height: var(--height);
        
            figure {
                height: var(--height);
                
                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
    
            &:not(:last-child) {
                margin-bottom: var(--gap);
            }

            &.--double {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: var(--gap);
            }
        }
    }
}
