@use "sass:math";

:root {
    --font-sans: 'Galano Grotesque', sans-serif;

    --font-size-xxxlarge: #{math.div(95px, 16px) * 1rem};
    --font-size-xxlarge: #{math.div(60px, 16px) * 1rem};
    --font-size-xlarge: #{math.div(55px, 16px) * 1rem};
    // --font-size-large: #{math.div(32px, 16px) * 1rem};
    --font-size-medium: #{math.div(30px, 16px) * 1rem};
    --font-size-base: #{math.div(24px, 16px) * 1rem};
    // --font-size-small: #{math.div(20px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(18px, 16px) * 1rem};
    --font-size-xxsmall: #{math.div(16px, 16px) * 1rem};
    --font-size-xxxsmall: #{math.div(10px, 16px) * 1rem};
    
    --zoom: 1;
    --line-height-sans: 1;
    --letter-spacing: -0.06em;
    
    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size-xxxlarge: #{math.div(46px, 16px) * 1rem};
        --font-size-xxlarge: #{math.div(42px, 16px) * 1rem};
        --font-size-xlarge: #{math.div(34px, 16px) * 1rem};
        
        --font-size-medium: #{math.div(24px, 16px) * 1rem};
        --font-size-base: #{math.div(20px, 16px) * 1rem};
        
        --font-size-xsmall: #{math.div(14px, 16px) * 1rem};
        --font-size-xxsmall: #{math.div(14px, 16px) * 1rem};
        --font-size-xxxsmall: #{math.div(8px, 16px) * 1rem};

        --font-size: 16px;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-bold($line-height: 0) {
    @include font-sans($line-height);
    font-weight: 600;
}

@mixin font-sans-heavy($line-height: 0) {
    @include font-sans($line-height);
    font-weight: 800;
}
