.building-text {
    --font-size: var(--font-size-base);
    --font-size-note: var(--font-size-xxsmall);

    --color: var(--black);

    --padding-h: var(--padding-xxxl);
    --padding-top: var(--padding-xxl);
    --padding-bottom: var(--padding-large);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        --padding-top: var(--padding-l);
        --padding-bottom: var(--padding-l);
    }
}

.building-text {
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    .wysiwyg {
        @include font-sans-bold();
        font-size: var(--font-size);
        color: var(--color);
        flex: 1 0 70;
        max-width: rem(805);

        p:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
    }

    .thx {
        padding: var(--padding-xxs);
        border-radius: var(--padding-xxs);
        background-color: var(--white);
        flex: 0 0 rem(200);

        figure {
            width: 100%;
        }

        > .title {
            @include font-sans-heavy();
            font-size: var(--font-size-note);
            text-transform: uppercase;
            color: var(--color);
            margin-bottom: .78em;
        }
    }

    @media (max-width: $smartphone) {
        .thx {
            margin-top: var(--padding-xl);
        } 
    }

    @media (min-width: $smartphone) {
        .wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: var(--padding-xxl);
            align-items: flex-end;
            justify-content: space-between;
        }
    }
}
