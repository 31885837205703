.circle {
    --padding-top: var(--padding-s);
    --padding-bottom: var(--padding-xxxs);
    --padding-h: var(--padding-xs);
    
    --radius: 50%;
    --bg: var(--pink);
    --color: var(--black);
    --font-size: var(--font-size-xxxlarge);
    --width: #{rem(141)};
    --height: #{rem(141)};
    
    @media (max-width: $smartphone) {
        --padding-top: var(--padding-xs);
        --width: #{rem(68)};
        --height: #{rem(68)};    
    }
}

.circle {
    @include basic-a();
    color: var(--color);
    background-color: var(--bg);
    
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    min-width: var(--width);
    height: var(--height);
    border-radius: var(--radius);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include font-sans-heavy(.7);
    font-size: var(--font-size);
    text-transform: uppercase;
}