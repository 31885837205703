.tag {
    // --padding-top: .2em;
    // --padding-bottom: .125em;
    --height: 1.05em;
    --padding-right: .2em;
    --padding-left: .2em;
    --radius-left: 1em;
    --radius-right: 1em;
    --bg: var(--black);
    --color: var(--white);
    --font-size: var(--font-size-base);
    
    &.--left {
        --radius-right: .083em;
    }
    
    &.--right {
        --radius-left: .083em;
    }
       
    &.--blue {
        --color: var(--black);
        --bg: var(--blue);
    }
    
    &.--white {
        --color: var(--black);
        --bg: var(--white);
    }
    
    &.--black {
        --color: var(--white);
        --bg: var(--black); 
    }
    
    &.--black-pink {
        --color: var(--pink);
        --bg: var(--black); 
    }
    
    &.--green {
        --color: var(--black);
        --bg: var(--green); 
    }

    &.--xl {
        --font-size: var(--font-size-xlarge);
    }

    &.--xs {
        --font-size: var(--font-size-xxsmall);
    }

    &.--xxxl {
        --font-size: var(--font-size-xxxlarge);
        
        &.--left {
            --padding-left: .3em;
        }
        
        &.--right {
            --padding-right: .3em;
        }
    }
}

.tag {
    @include basic-a();
    color: var(--color);
    background-color: var(--bg);
    //padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    padding: 0 var(--padding-right) 0 var(--padding-left);
    height: var(--height);
    
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border-top-left-radius: var(--radius-left);
    border-top-right-radius: var(--radius-right);
    border-bottom-left-radius: var(--radius-left);
    border-bottom-right-radius: var(--radius-right);

    @include font-sans-heavy(1);
    font-size: var(--font-size);
    text-transform: uppercase;
    text-align: center;
}