.shadow {
    --bg: var(--white);
    --color: var(--black);
    --font-size: var(--font-size-xxxlarge);
}

.shadow {
    color: var(--color);
    -webkit-text-stroke: .2em var(--bg);
    paint-order: stroke fill;
    
    @include font-sans-heavy(.7);
    font-size: var(--font-size);
    text-transform: uppercase;
}