.block-project-list {    
    @media (min-width: $smartphone) {
        --grid-cols: 5;
        --padding: var(--padding-xs);

        .--only-on-desktop { display: block  };
        .--only-on-mobile { display: none  };
    }

    @media (max-width: $smartphone) {
        --grid-cols: 2;
        --padding: var(--padding-xs);

        .--only-on-mobile { display: block  };
        .--only-on-desktop { display: none  };
    }

    &.--list {
        .--only-on-mobile,
        .--only-on-desktop,
        .--disabled { display: none !important; };
    }
}

.block-project-list {
    &:not(.--list) ul {
        display: grid;
        gap: var(--padding);
        grid-template-columns: repeat(var(--grid-cols), 1fr);

        li {
            position: relative;
            padding-top: 100%;

            > .card {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    ul {
        position: relative;
        padding: var(--padding);

        li {

            &.--hidden {
                display: none;
            }
        }
    }

    &.--list {
        ul {
            padding-left: 0;
            padding-right: 0;
        }
    }
    
    @media (max-width: $smartphone) {
        &.--list {
            ul {
                padding-top: var(--padding-xxl);
            }
        }
    }
}
