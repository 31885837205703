.filters {
    --radius: 1em;
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-xs);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xxs);
    }
}
    
.filters {
    @include z-index($z-index-header);
    position: fixed;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .buttons {
        @include z-index(1);
        position: absolute;
        pointer-events: none;

        .panel {
            > div {
                pointer-events: all;
                padding: var(--padding-v) var(--padding-h);
                background-color: var(--white);
                border-radius: var(--radius);
            }
        }
    }

    .toggles {
        @include z-index(0);
        position: absolute;
        bottom: 100%;
        margin-bottom: var(--padding-xxxxxs);

        --bg: var(--black);
        --color: var(--white);
        
        .--active  {
            --bg: var(--white);
            --color: var(--black);
            pointer-events: none;
        }
        
        border-radius: rem(20);
        background-color: var(--bg);
        display: flex;
        justify-content: flex-end;
        
        button {
            display: flex;
            background-color: transparent;
            padding: 0;
            border-radius: rem(20);
            background-color: var(--bg);
        }

        .icon {
            height: rem(40);
            width: rem(40);

            svg {
                path, rect {
                    fill: var(--color)
                }
            }
        }
    }

    .links {
        @include z-index(2);
        position: relative;
        width: 100%;
    }

    @media (max-width: $smartphone) {
        left: var(--padding-xs);
        right: var(--padding-xs);
        bottom: var(--padding-xs);
        
        .buttons {
            border-radius: var(--radius);
            background-color: var(--pink);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding: var(--padding-xxxs);
            gap: var(--padding-xxxs);
            max-height: 60vh;
            max-height: 60vh;
            bottom: 100%;
            width: 100%;

            .panel {
                > div {
                    height: 100%;
                }
            }
        }
        
        .links {
            border-radius: var(--radius);
            background-color: var(--pink);
            padding: var(--padding-xxxs);
            gap: var(--padding-xxxxs);
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            
            .tag {
                --font-size: #{rem(18)};
                --height: 1.5em;
                text-align: center;
            }

            button {
                grid-column: span 2;
            }
        }
    }

    @media (min-width: $smartphone) {
        bottom: var(--padding-l);
        left: 50%;
        transform: translate3d(-50%, 0, 0);

        .buttons {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            overflow: hidden;
            bottom: 100%;
            width: 100%;
            left: 0;

            .panel {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                > div {
                    pointer-events: all;
                    padding: var(--padding-v) var(--padding-h);
                    background-color: var(--white);
                    border-radius: var(--radius);
                }
            }
        }
        
        .links {
            display: flex;
            padding: rem(2);
            gap: rem(2);

            background-color: var(--pink);
            border-radius: var(--radius);
        }
    }
}