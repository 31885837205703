.edition-info {
    --font-size: var(--font-size-base);
    --arrow: #{rem(140)};
    
    --padding-h: var(--padding-xxxl);
    --padding-top: var(--padding-xxl);
    --padding-bottom: var(--padding-xxxxl);
    
    @media (max-width: $smartphone) {
         --arrow: #{rem(54)};
        --padding-h: var(--padding-xs);
        --padding-top: var(--padding-xl);
        --padding-bottom: var(--padding-xl);
    }
}

.edition-info {
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    .wysiwyg {
        @include font-sans-bold();
        font-size: var(--font-size);
        color: var(--lightGrey);
        max-width: rem(602);

        > p:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
    }

    .cities {
        display: flex;
        align-items: center;

        svg {
            height: var(--arrow);
            width: var(--arrow);
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--padding-xs);

        &:not(:last-child) {
            margin-bottom: var(--padding-xl);
        }
    }
    
    @media (max-width: $smartphone) {
        .info {
            &:not(:last-child) {
                margin-bottom: var(--padding-l);
            }
        }
        
        .wysiwyg {
            margin-top: var(--padding-xl);
        }
    }

    @media (min-width: $smartphone) {
        .wrapper {
            display: flex;
            align-items: flex-start;
            gap: var(--padding-xxxl);
        }
    }
}
