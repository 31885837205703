.custom-radio {
    &:not(:last-child) {
        margin-bottom: var(--padding-xs);
    }

    --border-color: var(--primary-color);

    &.--success {
        --border-color: var(--primary-color);
    }
    
    &.--error {
        --border-color: var(--color-error);
    }

    input {
        display: none;
    }

    label {
        color: var(--primary-color);
        display: flex;
        align-items: center;

        > span {
            cursor: pointer;
        }
    }

    input:disabled + label {
        pointer-events: none;
        opacity: .5;
    }

    .checkmark {
        border: 1px solid var(--border-color);
        content: '';
        display: block;
        height: rem(25);
        margin-right: var(--padding-xxs);
        min-width: rem(25);
        position: relative;
        width: rem(25);
        border-radius: rem(25);

        > span {
            display: none;
            pointer-events: none;

            top: 50%;
            left: 50%;
            width: rem(10);
            height: rem(10);
            background-color: var(--primary-color);

            position: absolute;
            transform: translate3d(-50%, -50%, 0);

            border-radius: 50%;
        }
    }

    input:checked + label .checkmark > span {
        display: block;
    }
}
