.block-project {
    --font-size: var(--font-size-base);

    --padding-v: 0;
    --padding-h: var(--padding-m);

    --padding-inner-v: var(--padding-l);
    --padding-inner-h: var(--padding-s);

    --gap: var(--padding-m);
    --radius: #{rem(16)};
    
    --img-height: #{rem(680)};
    --img-width: #{rem(680)};
    
    --bg: var(--pink);
    --color: var(--black);
    
    &.--left {
        --bg: var(--blue);
    }
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
        
        --padding-inner-v: var(--padding-xs);
        --padding-inner-h: var(--padding-xs);
        
        --img-width: 100%;
    }
}

.block-project {
    @include font-sans-bold();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    margin-bottom: var(--padding-m);
    
    .wrapper {
        border-radius: var(--radius);
        padding: var(--padding-inner-v) var(--padding-inner-h);
        background-color: var(--bg);
        color: var(--color);
    }

    figure {
        border-radius: var(--radius);
        width: var(--img-width);
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .text {
        a {
            @include basic-a();
            background-color: var(--black);
            color: var(--white);
            border-radius: rem(36);
            padding: 0 rem(40);
            display: inline-block;
            margin-top: var(--padding-l);
            text-align: center;
        }
    }

    @media (max-width: $smartphone) {
        figure {
            width: var(--img-width);
            padding-top: 100%;
            margin-bottom: var(--padding-m);

            img {
                position: absolute;
            }
        }

        .tag:first-child {
            margin-bottom: var(--padding-xxxs);
        }

        .text {
            margin-top: var(--padding-m);

            a {
                border-radius: rem(18);
                padding: 0 rem(20);
                display: inline-block;
                margin-top: var(--padding-m);
                width: 100%;
            }
        }

    }

    @media (min-width: $smartphone) {
        .tag:first-child {
            margin-bottom: var(--padding-xs);
        }
    
        .wrapper {
            display: flex;
            gap: var(--gap);
        }
        
        &.--right {
            .wrapper {
                flex-direction: row-reverse;
            }
        }

        figure {
            flex: 0 0 var(--img-width);
            min-height: var(--img-height);
        }

        .text {
            padding: var(--padding-xl) var(--padding-xl) 0;

            a {
                margin-top: var(--padding-xl);
            }
        }
    }
}
