.block-organiza {
    --font-size: var(--font-size-base);

    --padding-v: var(--padding-l);
    --padding-h: var(--padding-s);

    --gap: var(--padding-m);

    @media (max-width: $smartphone) {
        --padding-v: 0;
        --padding-h: var(--padding-xs);
    }
}

.block-organiza {
    @include font-sans-bold();
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    margin-bottom: var(--padding-m);

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
   
    .tag:first-child {
        margin-bottom: var(--padding-xs);
    }
    
    .text {
        a {
            @include basic-a();
            background-color: var(--black);
            color: var(--white);
            border-radius: rem(36);
            padding: 0 rem(40);
            display: inline-block;
            margin-top: var(--padding-l);
            text-align: center;
        }
    }

    @media (max-width: $smartphone) {
        .tag:first-child {
            margin-bottom: var(--padding-xxxs);
        }

        .text {
            margin-top: var(--padding-m);

            a {
                border-radius: rem(18);
                padding: 0 rem(20);
                display: inline-block;
                margin-top: var(--padding-m);
                width: 100%;
            }
        }

    }

    @media (min-width: $smartphone) {
        display: flex;
        gap: var(--gap);

        .left {
            min-width: rem(820);
        }

        .text {
            padding-right: var(--padding-xl);

            a {
                margin-top: var(--padding-xl);
            }
        }
    }
}
