.block-about {
    --font-size: var(--font-size-xxlarge);
    
    --max-width: #{rem(1042)};
    --padding-top: var(--padding-xxl);
    --padding-bottom: var(--padding-xxxl);
    --padding-h: var(--padding-s);
    
    --radius-open-right: .2em;
    --radius-open-left: 1em;
    --radius: 1em;
    
    --icon-height: #{rem(540)};
    --icon-width: #{rem(1090)};
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-base);

        --icon-height: auto;
        --icon-width: 100%;

        --padding-top: var(--padding-l);
        --padding-bottom: var(--padding-l);
        --padding-h: var(--padding-xs);
    }
}

.block-about {
    @include font-sans-bold();
    font-size: var(--font-size);

    .wrapper {
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        max-width: var(--max-width);
        margin: auto;
    }
    
    .open-tag {
        @include font-sans-heavy(1);
        display: inline-block;

        background-color: var(--pink);
        color: var(--black);
        border-top-left-radius: var(--radius-open-left);
        border-top-right-radius: 0;
        border-bottom-left-radius: var(--radius-open-left);
        border-bottom-right-radius: var(--radius-open-right);
        padding: 0 .12em;
    }
    
    .color-tag {
        @include font-sans-heavy();
    }

    .billboard {  
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: var(--header-height) var(--padding-m) var(--padding-m);
        position: relative;

        .overlay {
            @include z-index(0);
            background-color: var(--black);
            left: 0;
            opacity: .15;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .media-holder {
            left: 0;
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        svg {
            display: block;
            @include z-index(1);
            height: var(--icon-height);
            width: var(--icon-width);
        }
    }

    @media (max-width: $smartphone) {
        .billboard {  
            height: 80vh;
        }
    }
}
